<template>
  <standard-page :definition="$DEFINITIONS.did" sub_title="Decentralized Identifiers" v-bind:title="catalogName">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link>
      </span>
      <span v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</span>
    </template>
    <template v-slot:buttons>
      <span class="d-none d-xl-block">
        <router-link :to="{name: 'adddid', params: { catalogid: catalogid}}" class="btn btn-orange rounded font-weight-bold text-nowrap" title="Add existing DID to Catalog">Add existing DID
        </router-link>
        <router-link :to="{name: 'newdid', params: { catalogid: catalogid,catalogNameProp: catalogName }}" class="btn btn-orange rounded font-weight-bold mg-l-5" title="Create a new DID and add it to this Catalog">
          Create a new DID
        </router-link>
        <input @change="uploaded" accept=".xls,.xlsx" id="file" style="display:none;" type="file" />
        <button data-target="#bulkDidModal" data-toggle="modal" id="openModal" style="display: none" />
        <bulk-did-confirm-modal :dids="newDids" @confirm="submitDids" />
        <button @click="importDids" class="btn btn-indigo rounded mg-l-5" title="Import DIDs">
          <i class="fas fa-upload" />
        </button>
        <a class="btn btn-indigo rounded mg-l-5" download href="../../../../../../assets/bulk_did_format.xlsx" title="Export Template">
          <i class="fas fa-download" />
        </a>
      </span>
      <span class="d-xl-none btn-icon-list float-right">
        <router-link :to="{name: 'adddid', params: { catalogid: catalogid}}" class="btn btn-icon btn-orange rounded" title="Add existing DID to Catalog">
          <i class="typcn typcn-edit" />
        </router-link>
        <router-link :to="{name: 'newdid', params: { catalogid: catalogid,catalogNameProp: catalogName }}" class="btn btn-icon btn-orange rounded" title="Create a new DID and add it to this Catalog">
          <i class="typcn typcn-document-add" />
        </router-link>
        <button @click="importDids" class="btn btn-indigo rounded btn-icon" title="Import DIDs">
          <i class="typcn typcn-upload" />
        </button>
        <a class="btn btn-indigo rounded btn-icon" download href="../../../../../../assets/bulk_did_format.xlsx" title="Export Template">
          <i class="typcn typcn-download" />
        </a>
      </span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :rename="renameDid" :rename-col="1" :row-click="view" @ready="onReady" list-key="dids"></data-table>
      <delete-modal :delete-function="removeDid" :message="'Are you sure you want to remove this DID from this Catalog?'" :service="'identity'" :table="table" :url="`identity/catalogs/${didToRemove}`" v-if="didToRemove"></delete-modal>
    </template>

  </standard-page>
</template>

<script>
import { mapGetters } from "vuex";
import XLSX from 'xlsx';
import BulkDidConfirmModal from "./actions/BulkDidConfirmModal";
import EventBus from "@/event-bus";


export default {
  name: "identities-catalogs-dids",
  props: ['catalogid', 'catalogNameProp'],
  components: { BulkDidConfirmModal },
  computed: {
    ...mapGetters(["currentUser"])
  },
  data() {
    return {
      api: `${this.$identity.defaults.baseURL}/identity/catalogs/${this.catalogid}/dids`,
      columns: [
        { type: 'did' },
        { title: 'Label', data: 'short_name' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          defaultContent:
            `<div class="btn-group btn-icon-list">
                                    <a  class="permissions mg-r-10"
                                        href="javascript:void(0);"
                                        title="Permissions">
                                    <i class="fa fa-user-lock"></i>
                                    </a>
                                    ${this.$StandardButtons.renameButton('Change Did label')}
                                    ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Remove from Catalog', 'Remove')}
                                </div>`
        },
      ],
      table: null,
      catalogName: this.catalogNameProp,
      selectedDid: null,
      dids: [],
      checkedDids: [],
      newDids: [],
      didCount: 0,
      didComplete: 0,
      didToRemove: null,
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    onReady(table) {
      this.table = table;
      let self = this;
      $(async function () {
        $('#main-table tbody').on('click', '.permissions', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.storeDid(entity.did);
        });
        $('#main-table tbody').on('click', '.delete', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.setDidToRemove(entity.did);
          table.ajax.reload();
        });
      });
    },
    async init() {
      if (!this.catalogName) {
        this.getCatalog();
      }
    },
    async getCatalog() {
      try {
        let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
        this.catalogName = reply.data ? reply.data.name : null;
      } catch (e) {
        this.$toastr.e("Error getting catalog name", "Error");
      }
    },
    async importDids() {
      document.getElementById("file").click();
    },
    async uploaded(e) {
      if (e.target.files.length > 0) {
        EventBus.$emit('openLoader');
        await this.parseExcel(e.target.files[0]);
        EventBus.$emit('closeLoader');
      }
    },
    async parseExcel(file) {
      let reader = new FileReader();
      let self = this;
      reader.onload = function (e) {
        let data = e.target.result;
        let workbook = XLSX.read(data, {
          type: 'binary'
        });
        let schemaRows = Array.from(XLSX.utils.sheet_to_row_object_array(workbook.Sheets["schema"]));
        // console.log("schemaRows", schemaRows);

        self.newDids = [];
        for (let sheet in workbook.Sheets) {
          if (sheet != "schema") {
            // console.log("workbook.Sheets[sheet]", workbook.Sheets[sheet]);

            let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
            // console.log('XL_row_object', XL_row_object);

            XL_row_object.forEach(r => {
              let newDid = {
                properties: [],
                claims: []
              };
              schemaRows.forEach(schemaRow => {
                let columnName = schemaRow["Column name"];
                if (schemaRow["Data Type"] != "None" && typeof r[columnName] != 'undefined') {
                  let property = {
                    name: columnName,
                    private: schemaRow["Private"] == "YES"
                  };
                  switch (schemaRow["Data Type"]) {
                    case "String":
                      property.value = "" + r[columnName];
                      property.type = "String";
                      break;
                    case "Boolean":
                      property.value = r[columnName] == "YES" ? "true" : "false";
                      property.type = "Bool";
                      break;
                    case "Number":
                      property.value = '' + parseInt(r[columnName]);
                      property.type = "U128";
                      break;
                  }

                  newDid.properties.push(property);
                }
              });
              if (newDid.properties.length > 0) {
                self.newDids.push(newDid);
              }
            });
          }
        }
        $("#openModal").trigger("click");
        document.getElementById('file').value = '';
      };

      reader.onerror = function (ex) {
        EventBus.$emit('closeLoader');
        console.log(ex);
        document.getElementById('file').value = '';
      };

      reader.readAsBinaryString(file);
    },
    setData(input, obj) {
      switch (input["Data Type"]) {
        case "String":
          obj["type"] = "String";
          obj["value"] = input["String Value"] ? (input["String Value"] + "") : "";
          break;
        case "Date":
          obj["type"] = "String";
          obj["value"] = input["Date Value"] ? (input["Date Value"] + "") : "";
          break;
        case "Boolean":
          obj["type"] = "Bool";
          obj["value"] = input["Boolean Value"] && (input["Boolean Value"].toUpperCase() === "YES") ? "true" : "false";
          break;
        case "Number":
          let val = input["Number Value"] ? input["Number Value"] : 0;
          if (val >= 0 && val < 256) {
            obj["type"] = "U8";
            obj["value"] = val;
          } else if (val > 255 && val < 65537) {
            obj["type"] = "U16";
            obj["value"] = val;
          } else if (val > 65536 && val < 4000001) {
            obj["type"] = "U32";
            obj["value"] = val;
          } else {
            obj["type"] = "U128";
            obj["value"] = val;
          }
      }

    },
    async submitDids() {
      let toast = this.$toastr.Add({
        msg: "Creating bulk DIDs - Please wait",
        clickClose: false,
        timeout: 0,
        position: "toast-top-full-width",
        type: "Information"
      });
      try {
        if (typeof this.catalogid != 'undefined') {
          let that = this;
          this.didCount = this.newDids.length;
          this.newDids.forEach(did => {
            let shortNamePropertyIndex = did.properties.findIndex(p => p.name == "short_name");
            if (shortNamePropertyIndex != -1) {
              did.short_name = did.properties[shortNamePropertyIndex].value;
              did.properties.splice(shortNamePropertyIndex, 1);
            } else {
              let nameProperty = did.properties.find(p => p.name == "Name");
              if (nameProperty) {
                did.short_name = nameProperty.value;
              } else {
                let firstNonPrivate = did.properties.find(p => !p.private);
                if (firstNonPrivate) {
                  did.short_name = firstNonPrivate.value;
                } else {
                  console.log('No suituable property for short_name found. Not setting short_name');
                }
              }
            }
            did.catalog_id = parseInt(that.catalogid);
          });
          console.log(this.newDids);
          console.time('bulkdids');

          var i, j, temparray, chunk = 4;
          for (i = 0, j = this.newDids.length; i < j; i += chunk) {
            toast.msg = `Creating bulk DIDs - ${i} of ${this.newDids.length} Please wait`;
            temparray = this.newDids.slice(i, i + chunk);
            console.log(`Creating ${temparray.length} Dids`);
            await this.$identity.post(`/identity/dids/bulk`, { dids: temparray });
          }

          console.timeEnd('bulkdids');
          this.$toastr.Close(toast);
          this.$toastr.i("Bulk upload sucessful", "Success");

        } else {
          this.$toastr.Close(toast);
          this.$toastr.e("Application and Registry not selected", "Error");
        }
      } catch (e) {
        console.log(e);
        this.$toastr.Close(toast);
        this.$toastr.i("Error creating bulk DIDs", "Error");
      }
      this.table.ajax.reload();

    },
    async submitDID(did) {
      did.catalogid = this.catalogid;
      await this.$identity.post(`/identity/controller/dids`, did);
      this.didComplete++;
      this.$toastr.s(`${this.didComplete} of ${this.didCount} DID created on chain`, "Successful");
      if (this.didCount === this.didComplete) {
        this.didCount = this.didComplete = 0;
        this.table.ajax.reload();
      }
    },
    view(did) {
      this.$router.push({ name: 'viewdid', params: { catalogid: this.catalogid, catalogNameProp: this.catalogNameProp, did: did.did, short_name: did.short_name } });
    },
    storeDid(did) {
      this.$router.push({ name: 'didauthorization', params: { did: did } });
    },
    async removeDid() {
      try {
        EventBus.$emit('openLoader');
        await this.$identity.patch(`identity/catalogs/${this.catalogid}/dids`, {
          remove: [this.didToRemove]
        });
        this.$toastr.s("DID removed from catalog.", 'Success');
        this.checkedDids = [];
      } catch (e) {
        this.$toastr.e("Failed to remove DID from catalog", 'Error');
      } finally {
        EventBus.$emit('closeLoader');
        this.table.ajax.reload();
      }
    },
    setDidToRemove(did) {
      this.didToRemove = did;
    },
    async renameDid(did, name) {
      if (name.trim().length > 0) {
        try {
          EventBus.$emit('openLoader');
          await this.$identity.patch(`identity/catalogs/${this.catalogid}/dids`, { add: [{ did: did.did, short_name: name }], remove: [did.did] });
          this.$toastr.s(this.$DEFINITIONS.identity.toasts.renameDid, 'Success');
        } catch (e) {
          console.log(e);
          this.$toastr.e("Did rename failed", 'Error');
        } finally {
          EventBus.$emit('closeLoader');
          this.table.ajax.reload();
        }
      } else {
        this.$toastr.e("Please provide a label", 'Failed');
      }
    },
  }
}
</script>

<style scoped>
.masked {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-margin {
  margin-top: 24px !important;
  margin-left: -25px !important;
}
</style>