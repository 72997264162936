<template>
  <div aria-hidden="true" class="modal" id="bulkDidModal">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-header">
          <h6 class="modal-title">You are about create {{dids.length}} DIDs, are you sure?</h6>
          <button aria-label="Close" class="close" data-dismiss="modal" id="close" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div class="modal-body pd-5" style="height: 70vh; overflow-y: scroll;">
          <div class="az-content-body az-content-body-profile">
            <nav class="nav az-nav-line">
              <a :class="{active: activeTab === idx}" :href="'#did_'+idx" @click="navigate(idx)" class="nav-link" data-toggle="tab" v-for="(row, idx) in rows">{{''+(idx + 1)}}</a>
            </nav>
            <div class="az-tab-content mg-t-10">
              <div :class="{'d-none': idx !== activeTab }" :id="'did_'+idx" v-for="(row, idx) in rows">
                <div class="table-responsive">
                  <table class="table table-bordered mg-b-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Fields</th>
                        <th>Key</th>
                        <th>Data Type</th>
                        <th>Value</th>
                        <th>Private</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(subrow, indx) in row">
                        <td :rowspan="subrow.didSpan" v-if="subrow.didGroup">{{subrow.subjectName}}</td>
                        <td :rowspan="subrow.subjectSpan" v-if="subrow.subjectGroup">{{subrow.subject}}</td>
                        <td>{{subrow["name"]}}{{subrow["assertion"]}}</td>
                        <td>{{subrow["type"]}}</td>
                        <td>{{subrow["value"]}}</td>
                        <td>{{subrow["private"]}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div><!-- az-profile-body -->
            </div>
          </div>
        </div><!-- modal-body -->
        <div class="modal-footer">
          <button @click="confirm()" class="btn btn-indigo rounded font-weight-bol " data-dismiss="modal" type="button">Confirm</button>
          <button class="btn btn-light rounded font-weight-bold" data-dismiss="modal" type="button">Cancel</button>
        </div>
      </div>
    </div><!-- modal-dialog -->
  </div>
</template>

<script>
export default {
  name: "BulkDidConfirmModal",
  props: { dids: Array },
  watch: {
    dids: function (nv, ov) {
      this.setUpRows();
    }
  },
  data() {
    return {
      rows: [],
      totalRows: 0,
      activeTab: 0
    };
  },
  mounted() {
    this.setUpRows();
  },
  methods: {
    setUpRows() {
      this.totalRows = 0;

      this.dids.forEach((did, index, arr) => {
        let subjectName = did.short_name;
        let didGroup = true;
        let propGroup = true;
        let claimGroup = true;
        let rows = [];
        did.properties.forEach(prop => {
          this.totalRows++;
          rows.push({
            ...prop,
            did: index + 1,
            subjectName: subjectName,
            didGroup: didGroup,
            didSpan: did.properties.length + did.claims.length,
            subject: "Properties",
            subjectGroup: propGroup,
            subjectSpan: did.properties.length
          });
          if (didGroup) {
            didGroup = false;
          }
          if (propGroup) {
            propGroup = false;
          }
        });
        did.claims.forEach(claim => {
          this.totalRows++;
          rows.push({
            ...claim,
            did: index + 1,
            subjectName: subjectName,
            didGroup: didGroup,
            didSpan: did.properties.length + did.claims.length,
            subject: "Claims",
            subjectGroup: claimGroup,
            subjectSpan: did.claims.length
          });
          if (didGroup) {
            didGroup = false;
          }
          if (claimGroup) {
            claimGroup = false;
          }
        });
        this.rows.push(rows);
      });
    },
    confirm() {
      this.activeTab = 0;
      this.$emit("confirm");
    },
    navigate(index) {
      this.activeTab = index;
    }
  }
}
</script>

<style scoped>
</style>